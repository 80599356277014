// @flow strict

import {
  Fragment,
  type Node,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';

import {
  isMobileDevice,
  setStyle,
  useConfigContext,
  useEventContext,
} from '@omq/shared';
import {ConfigCategoryDisplayTypes, ConfigMobileSearchDisplayTypes, type Placeholder} from '@omq/flow';

import type { HelpCategory, HelpPage, HelpSearchParams } from '../api/help';
import type { HelpURLParams } from '../stores/url-store';

import { CategoryNavigation } from '../components/navigation/category-navigation';
import { Search } from '../components/search/search';
import { CategoryGrid } from '../components/category-grid/category-grid';
import { QuestionList } from '../components/question-list/question-list';
import { useHelpPage } from '../hooks/help-page';
import { useURLParams } from '../hooks/url-params';
import { ErrorView } from './error-view';
import { useAutoComplete } from '../hooks/auto-complete';
import { useFaqStructuredData } from '../hooks/faq-structured-data';
import { keepAutoComplete } from '../utils/keep-auto-complete';
import { useCanonical } from '../hooks/canonical';
import type { ServerError } from '@omq/shared';

/**
 * Type for component properties.
 */
type HelpViewProps = {
  initialHelpPage: HelpPage | null,
  initialSearchValue: string,
  keepFocus?: boolean,
  placeholder: Placeholder,
};

/**
 * Helper function.
 * Find help category by id.
 *
 * @param {Array<HelpCategory>} categories - array of help categories
 * @param {number|null} selectedCategory - selected category
 *
 * @returns {HelpCategory|null}
 */
function getSelectedHelpCategory(
  categories: Array<HelpCategory>,
  selectedCategory: number | null,
): HelpCategory | null {
  if (selectedCategory == null) {
    return null;
  }

  return (
    categories.find((category) => category.id === selectedCategory) || null
  );
}

/**
 * Component description.
 *
 * @param {HelpViewProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.5
 *
 * @returns {Node}
 */
export function HelpView({
  initialHelpPage,
  initialSearchValue,
  keepFocus = false,
  placeholder,
}: HelpViewProps): Node {
  const config = useConfigContext();
  const useSearchPopup = isMobileDevice() &&
    config.mobileSearchDisplayType === ConfigMobileSearchDisplayTypes.POPUP;
  const searchInputRef = useRef();

  const [searchValue, setSearchValue] = useState<string>(initialSearchValue);
  const [currentHelpPage, setCurrentHelpPage] = useState<number>(-1);
  const [currentSearchPage, setCurrentSearchPage] = useState<number>(-1);

  // keep track of search input focus
  // mobile devices need to show search view on focus
  const [searchInputHasFocus, setSearchInputFocus] = useState<boolean>(false);

  // get urlParams
  const [urlParams, updateURL] = useURLParams((urlParams: HelpURLParams) => {
    setSelectedCategory(urlParams.category);
    setSelectedQuestion(urlParams.question);
  });

  // set up states
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(
    urlParams.question,
  );
  const [selectedCategory, setSelectedCategory] = useState<number | null>(
    urlParams.category,
  );

  // get current help page
  const [helpPage, error] = useHelpPage(
    selectedCategory,
    currentHelpPage,
    initialHelpPage,
    placeholder,
  );
  useFaqStructuredData(helpPage);

  const [currentError, setCurrentError] = useState<ServerError>(error);

  // get the search result
  // memoize search params, to prevent infinite search
  const searchParams = useMemo(() => {
    const params: HelpSearchParams = {};
    if (selectedCategory != null) {
      params.category = selectedCategory.toString();
    }

    // add page as search param
    if (currentSearchPage !== -1) {
      params.page = currentSearchPage;
    }

    return params;
  }, [selectedCategory, currentSearchPage]);

  const { questions, autoComplete, nextPage: nextSearchPage } = useAutoComplete(
    searchValue,
    placeholder,
    searchParams,
  );

  const searchIsActive = searchValue !== '' || (useSearchPopup && searchInputHasFocus);
  const isModalView = searchIsActive && useSearchPopup;
  const currentQuestions = searchIsActive ? questions : helpPage.questions;

  // add the canonical link for selected question
  useCanonical(
    selectedQuestion == null
      ? null
      : currentQuestions.find(
          (q) => q.id === selectedQuestion,
        ) || null,
  );

  // fire faq categories loaded event when categories change
  const pageEvent = useEventContext();
  useEffect(() => {
    pageEvent.dispatchFAQCategoriesLoaded(helpPage.categories);
  }, [helpPage.categories]);


  // store latest body position style
  const lastBodyPosition = useRef(null);
  const initialViewportHeight = useRef(window.visualViewport?.height || 0);
  const searchViewRef = useRef(null);

  /**
   * BUGFIX Safari server-5742
   *
   * Since safari does not prevent scrolling of elements behind
   * modal view, we do it here.
   */
  useEffect(() => {
    const visualViewportIsAvailable = window.visualViewport != null;

    // get the body element
    const body = document.body;
    if (body == null) {
      return;
    }

    // prevent scrolling while search input has focus
    function handleTouchMove(evt: TouchEvent) {
      evt.preventDefault();
    }

    // handle viewport changes (mobile keyboard opens, and
    // changes the size of visible part of the screen)
    function handleViewportChanges() {
      const modal = searchViewRef.current;

      // if there is no modal, or search is not a modal,
      // no further action required
      if (modal == null || !isModalView) {
        return;
      }

      // get new height and offset
      const { height, offsetTop } = window.visualViewport;

      // if viewport is smaller & offsetTop is higher than 0
      // it means, keyboard is open, and viewport has been pushed outside
      // the screen
      if (initialViewportHeight.current > height && offsetTop > 0) {
        // set modal to the height if the available space
        // and move it to the bottom
        setStyle(modal, {
          bottom: 0,
          height: `${height - 30}px`,
          width: '100%',
          top: 'unset',
        });

        // disable scrolling
        document.addEventListener('touchmove', handleTouchMove);
      } else {
        // keyboard is closed, reset styles
        setStyle(modal, {
          bottom: 0,
          top: null,
          height: 'auto',
          width: '100%',
        });

        // enable scrolling
        document.removeEventListener('touchmove', handleTouchMove);
      }
    }

    if (visualViewportIsAvailable) {
      // add listener to detect viewport changes (virtual keyboard open/close)
      window.visualViewport.addEventListener('resize', handleViewportChanges);
    }

    // if modal view is open
    if (isModalView) {
      // cache current style
      lastBodyPosition.current = body.style.position;

      // set body position to fixed
      // which disables scrolling for the entire site
      body.style.position = 'fixed';
      body.style.top = '0px';

      if (visualViewportIsAvailable) {
        handleViewportChanges();
      }
    } else {
      // if modal view has been closed
      if (lastBodyPosition.current != null) {
        // restore previous body style
        body.style.position = lastBodyPosition.current;
      }
    }

    // clean up
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);

      if (visualViewportIsAvailable) {
        window.visualViewport.removeEventListener(
          'resize',
          handleViewportChanges,
        );
      }

      // restore the previous body-style if there was one.
      if (lastBodyPosition.current != null) {
        body.style.position = lastBodyPosition.current;
      }

      // clean up the modal window
      const modal = searchViewRef.current;

      // if there is no modal, or search is not a modal,
      // no further action required
      if (modal == null || !isModalView) {
        return;
      }

      // keyboard is closed, reset styles
      setStyle(modal, {
        bottom: 0,
        top: null,
        height: 'auto',
        width: '100%',
      });
    };
  }, [isModalView]);

  if (currentError != null) {
    return <ErrorView errorMessage={config.loc(`_error.${currentError.errorType}`)} />;
  }

  const helpWrapperClassName = `${config.generateClassName('help-wrapper')} ${
    isModalView
      ? `${config.generateClassName('modal__panel')} mobile-search-input`
      : ''
  }`;

  const currentCategory =
    helpPage.categoryPath[helpPage.categoryPath.length - 1];

  const headline =
    currentCategory == null
      ? config.loc('faq')
      : config.loc('faq_category', currentCategory.name);

  const searchPlaceholder =
    currentCategory == null
      ? null
      : config.loc('search_placeholder_category', currentCategory.name);

  // handle category selections
  const onCategoryChange = (category) => {
    setSearchValue('');
    setSelectedQuestion(null);
    setCurrentHelpPage(-1);

    // If no category is selected, reset
    if (category === null) {
      updateURL('/', config.urlPatternType);
      setSelectedCategory(null);
    }
    // If the currently selected category is clicked again, it will be toggled off
    else if (category.id === selectedCategory) {
      const { parent } = category;

      // If it has no parent category, none will be selected
      if (parent === null) {
        updateURL('/', config.urlPatternType);
      }
      // If it has a parent category, that will be selected
      else {
        const parentCategoriesArray = helpPage.categories.find((innerArray) =>
          innerArray.some((obj) => obj.id === parent),
        );
        const parentCategory = parentCategoriesArray.find((obj) => obj.id === parent);
        updateURL(parentCategory.url, config.urlPatternType);
      }
      setSelectedCategory(parent);
    }
    // Otherwise, the new category is simply selected
    else {
      setSelectedCategory(category.id);
      updateURL(category.url, config.urlPatternType);
    }

    // set focus to input field after navigation
    // only on desktop (never do autofocus on mobile screens)
    if (!useSearchPopup && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  // handle input focus
  const onSearchFocus = () => {
    if (useSearchPopup) {
      setSearchInputFocus(true);
    }
  };

  // handle search input changes
  const onSearchChange = (value) => {
    // reset search page
    setCurrentSearchPage(-1);
    setSearchValue(value);
  };

  // handle search close
  const onSearchClose = () => {
    if (useSearchPopup) {
      setSearchValue('');
      setSearchInputFocus(false);
    }

    // clear/hide auto complete
    if (searchInputRef.current != null) {
      searchInputRef.current.clearAutoComplete();
    }
  };

  // Set the question as selected and lazy load its answers
  const onQuestionClick = (question) => {
    // clear/hide auto complete
    if (searchInputRef.current != null) {
      searchInputRef.current.clearAutoComplete();
    }

    const category = getSelectedHelpCategory(
      helpPage.categoryPath,
      selectedCategory,
    );

    updateURL(
      question != null ? question.url : category != null ? category.url : '/',
      config.urlPatternType,
    );

    setSelectedQuestion(question?.id ?? null);
  };

  // handle more button clicks
  const onMoreQuestionsClick = (event) => {
    event.preventDefault();

    // set next current page depending on search state
    searchIsActive
      ? setCurrentSearchPage(nextSearchPage)
      : setCurrentHelpPage(helpPage.nextPage);
  };

  // more button is visible if there is a next page
  // nextPage value depends on search flag - use nextPage from search result or from help page
  const moreButtonIsVisible = searchIsActive
    ? nextSearchPage !== -1
    : helpPage.nextPage !== -1;

  let categoriesGrids = null;

  // default behaviour
  if (config.categoryDisplayType === ConfigCategoryDisplayTypes.DISAPPEAR) {
    /* Do not show categories during search or if they are empty */
    if (!searchIsActive) {
      categoriesGrids = (
        <section className={config.generateClassName('help-container')}>
          <CategoryGrid
            categories={helpPage.categories[helpPage.categoryPath.length]}
            displayType={currentCategory == null ? 'large' : 'small'}
            onCategorySelection={onCategoryChange}
          />
        </section>
      );
    }
  } else if (
    config.categoryDisplayType === ConfigCategoryDisplayTypes.PERMANENT
  ) {
    categoriesGrids = (
      <section className={config.generateClassName('help-container')}>
        {helpPage.categories.map((value, index) => {
          return (
            <CategoryGrid
              key={index}
              categories={value}
              selectedCategory={helpPage.categoryPath[index] || null}
              displayType={index === 0 ? 'large' : 'small'}
              onCategorySelection={onCategoryChange}
            />
          );
        })}
      </section>
    );
  }

  // define if headline is visible
  // - not in modal view
  // - only if search value is empty
  const headlineIsVisible = !isModalView && searchValue === '';

  return (
    <Fragment>
      {/* Show modal background if view is in modal mode */}
      {isModalView && <div className={config.generateClassName('modal')} />}

      <div className={helpWrapperClassName} ref={searchViewRef}>
        {/* Do not show navigation in modal views */}
        {!isModalView && (
          <section className={config.generateClassName('help-container')}>
            <CategoryNavigation
              categories={helpPage.categoryPath}
              onCategorySelection={onCategoryChange}
            />
          </section>
        )}

        <section className={config.generateClassName('help-container')}>
          <Search
            value={searchValue}
            ref={searchInputRef}
            placeholder={searchPlaceholder}
            onFocus={onSearchFocus}
            autoFocus={!useSearchPopup && (keepFocus || config.isAutoFocusActive)}
            autoCompleteItems={autoComplete}
            onIconClick={() => onSearchChange('')}
            onChange={onSearchChange}
            onCloseClick={searchIsActive && useSearchPopup ? onSearchClose : null}
          />
        </section>

        {/* Never show categories in mobile search */}
        {!(useSearchPopup && searchIsActive) && categoriesGrids}

        {/* In Modal mode, do not show empty question list */}
        {!(isModalView && questions.length === 0) && (
          <section className={config.generateClassName('help-container')}>
            {/* No headline in modal pane */}
            {headlineIsVisible && (
              <h2
                className={config.generateClassName('question-list-headline')}>
                {headline}
              </h2>
            )}

            <QuestionList
              questions={currentQuestions}
              selectedQuestionId={selectedQuestion}
              onQuestionClick={onQuestionClick}
              isEmptyCategory={searchValue === '' && questions.length === 0}
              placeholder={placeholder}
              onError={setCurrentError}
            />

            {moreButtonIsVisible && (
              <a
                title={config.loc('more_questions')}
                className={config.generateClassName('more-questions')}
                onMouseDown={(evt) =>
                  keepAutoComplete(
                    evt,
                    config.generateClassName('search__input'),
                  )
                }
                onClick={onMoreQuestionsClick}>
                {config.loc('more_questions')}
              </a>
            )}
          </section>
        )}
      </div>
    </Fragment>
  );
}
